<template>
  <div class="user-wallet">
    <div class="m-user">
      <div class="mc">
        <img :src="userInfo.avatar" alt="" class="avatar">
        <div style="color: #fff;font-size: 14px;font-weight: bold">{{ userData.businessName }}</div>
        <div class="round-btn">已认证</div>
      </div>
      <div class="mb flex-row flex-col-center flex-row-center">
        <div class="text ">我们为您提供会员尊享服务</div>
      </div>
    </div>
    <div class="m-block">
      <div class="mt">
        <p class="text-lg">我的钱包</p>
      </div>
      <div class="mc">
        <ul>
          <li class="fore0">
            <div class="num">
              <a href="javascript:void(0)" @click="jumpYe()">{{ Money }}</a>
            </div>
            <div class="name">我的余额</div>
            <div class="extra"></div>
            <div class="operate">
              <a href="javascript:void(0)" @click="jumpCz()">充值</a>
            </div>
          </li>
          <li class="fore0">
            <div class="num">
              <a href="#">{{ walletData.todayPrice?.toFixed(2) || '0.00' }}</a>
            </div>
            <div class="name">今日采购额</div>
          </li>
          <li class="fore0">
            <div class="num">
              <a href="#">{{ walletData.yestPrice?.toFixed(2) || '0.00' }}</a>
            </div>
            <div class="name">昨日采购额</div>
          </li>
          <li class="fore0">
            <div class="num">
              <a href="#">{{ walletData.weekPrice?.toFixed(2) || '0.00' }}</a>
            </div>
            <div class="name">本周采购额</div>
          </li>
          <li class="fore0">
            <div class="num">
              <a href="#">{{ walletData.monthPrice?.toFixed(2) || '0.00' }}</a>
            </div>
            <div class="name">本月采购额</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2023/2/13
 * @version 1.0
 * @description 用户钱包
 */
import { mapGetters } from 'vuex'
import { getPriceTotal } from '@/api/Order'
import { getUserInfoRz } from '@/api/Login'
import { getMoney } from '@/api/finance/stream'

export default {
  name: 'UserWallet',
  computed: {
    ...mapGetters(['infoProfect', 'userInfo'])
  },
  data () {
    return {
      walletData: {},
      userData: {},
      Money: 0.00
    }
  },
  created() {
    if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
      getPriceTotal().then(res => {
        this.walletData = res.data
      })
      getUserInfoRz().then(res => {
        this.userData = res.data
      })
      getMoney().then(res => {
        this.Money = res.data.toFixed(2)
      })
    }
  },
  methods: {
    jumpYe() {
      let PAGE_URL = ''
      if (process.env.NODE_ENV === 'pre') {
        0 == this.$tp().IS_OPEN ? this.$router.push('/user/general/view') : PAGE_URL = 'http://cm.aijdan.com/finance/FinanceOverview'  // 退出回到商务中心index页面
      } else if (process.env.NODE_ENV === 'production') {
        0 == this.$tp().IS_OPEN ? this.$router.push('/user/general/view') : PAGE_URL = 'https://console.wanjingai.com/finance/FinanceOverview'  // 退出回到商务中心index页面
      } else {
        0 == this.$tp().IS_OPEN ? this.$router.push('/user/general/view') : PAGE_URL = 'http://cm.aijdan.com/finance/FinanceOverview'  // 退出回到商务中心index页面
      }
      if (this.$tp().IS_OPEN == 1) window.open(PAGE_URL, '_blank')
    },
    jumpCz() {
      let PAGE_URL = ''
      if (process.env.NODE_ENV === 'pre') {
        0 == this.$tp().IS_OPEN ? this.$router.push('/user/general/view') : PAGE_URL =  `http://cm.aijdan.com/finance/FinanceOverview?status=1` // 退出回到商务中心index页面
      } else if (process.env.NODE_ENV === 'production') {
        0 == this.$tp().IS_OPEN ? this.$router.push('/user/general/view') : PAGE_URL = `https://console.wanjingai.com/finance/FinanceOverview?status=1`  // 退出回到商务中心index页面
      } else {
        0 == this.$tp().IS_OPEN ? this.$router.push('/user/general/view') : PAGE_URL = `http://cm.aijdan.com/finance/FinanceOverview?status=1`  // 退出回到商务中心index页面
      }
      if (this.$tp().IS_OPEN == 1) window.open(PAGE_URL, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.user-wallet {
  margin-top: -20px;
  padding: 20px 0;
  height: 243px;
  box-sizing: unset;
}
.round-btn{
  border-radius: 20px;
  width: 80px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  color: white;
  margin: 0 auto;
  margin-top: 10px;
}

.m-user {
  float: left;
  margin-bottom: 0;
  width: 250px;
  height: 243px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, .3);

  .mc {
    background-color: #ff4000;
    position: relative;
    height: 187px;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
    }
  }

  .avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin: 20px auto;
    box-shadow: 3px 2px 10px 0 rgba(79, 5, 1, .84);
    overflow: hidden;
  }

  .mb {
    padding: 10px 20px;
    height: 56px;
    background-color: #232331;
    .text {
      float: left;
      margin-right: 10px;
      color: #f4ca3a;
    }
  }
}

.m-block {
  margin: 9px 0 9px 250px;
  overflow: visible;
  background-color: #fff;

  .mt {
    padding: 0 20px;
    height: 55px;
    line-height: 55px;
    border-bottom: 1px solid #f0f3ef;
    overflow: visible;
  }

  .mc {
    overflow: hidden;
    zoom: 1;
  }

  ul {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    height: 149px;
    overflow: hidden;
    li {
      color: #333;
    }
  }

  .num {
    margin-bottom: 10px;
    padding-top: 10px;
    height: 44px;
    a{
      line-height: 24px;
      font-size: 22px;
      font-weight: 900;
    }
    a:hover{
      color: #c81623;
    }
  }

  .name {
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    font-size: 12px;
    color: #333;
  }
  .extra{
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    color: #999;
    margin-bottom: 10px;
  }
  .operate{
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    a{
      float: left;
      font-size: 12px;
      color: #4184e8;
    }
  }
}
</style>
