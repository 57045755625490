<template>
  <div class="CarouselRank">
    <!--  每日推荐  -->
    <a-row style="margin-top: 32px" type="flex">
      <a-col :md="24" :span="24" class="aCol">
        <div class="top">
          <h4>每日推荐</h4>
          <p class="p" @click="$router.push({ name: 'Search', query: { SourceProductType: 3 } })">更多
            <a-icon style="margin-left: 16px" type="right" />
          </p>
        </div>
        <OwlCarousel v-if="DailyRecommendations.length > 0">
          <GoodsNormalCard
            v-for="(item) in DailyRecommendations"
            :key="item.id"
            :goods-data="item"
            @preview="(id)=>{ $emit('preview', id) }"
          >
          </GoodsNormalCard>
        </OwlCarousel>
      </a-col>
      <a-col flex="20px"></a-col>
    </a-row>
    <div class="flex-column">
      <h4 class="mt-30 mb-20">活动专区</h4>
      <img :src="require('@/assets/HomeBanner/act3.png')" alt="" style="max-width: 100%">
    </div>
    <!--  平台甄选  -->
    <a-row style="margin-top: 32px" type="flex">
      <a-col :md="24" :span="24" class="aCol">
        <div class="top">
          <h4>平台甄选</h4>
          <p class="p" @click="$router.push({ name: 'Search', query: { SourceProductType: 2 } })">更多
            <a-icon style="margin-left: 16px" type="right" />
          </p>
        </div>
        <OwlCarousel v-if="platformSelection.length > 0">
          <GoodsNormalCard
            v-for="(item) in platformSelection"
            :key="item.id"
            :goods-data="item"
            @preview="(id)=>{ $emit('preview', id) }"
          >
          </GoodsNormalCard>
          <!--          <a-card-->
          <!--            v-for="(item, index) in platformSelection"-->
          <!--            :key="index"-->
          <!--            :hoverable="true"-->
          <!--            class="goodsCard mr-10"-->
          <!--            style="max-width: 240px;height: 394px;"-->
          <!--            @click="$router.push('/commodity/' + item.id)">-->
          <!--            <img-->
          <!--              slot="cover"-->
          <!--              :src="item.imgUrl"-->
          <!--              alt="example"-->
          <!--              style="width: 238px; height: 238px"-->
          <!--            />-->
          <!--            <a-card-meta :title="item.name">-->
          <!--              <template slot="description">-->
          <!--                &lt;!&ndash;                <div v-if="item.subTitle">&ndash;&gt;-->
          <!--                &lt;!&ndash;                  {{ item.subTitle }}&ndash;&gt;-->
          <!--                &lt;!&ndash;                </div>&ndash;&gt;-->
          <!--                &lt;!&ndash;                <div v-else class="icon-null"></div>&ndash;&gt;-->
          <!--                <Ellipsis v-if="item.subTitle" :length="24">{{ item.subTitle }}</Ellipsis>-->
          <!--                <div v-else class="icon-null"></div>-->
          <!--                <div style="display: flex; justify-content: space-between">-->
          <!--                  <h5 v-if="Object.keys(userInfo).length !== 0 & userInfo.infoProfect !== 0 & userInfo.infoProfect !== 2">{{ '￥' + item.tradePrice }}</h5>-->
          <!--                  <div v-if="item.isPuint == 1" style="color:green;">已入库</div>-->
          <!--                  <div v-else style="color:green;" @click.stop="handleAddGoods(item)">{{ Object.keys(userInfo).length === 0 ? '' : userInfo.infoProfect === 0 ? '' : userInfo.infoProfect === 2 ? '' : '一键入库' }}</div>-->
          <!--                </div>-->
          <!--                <h6 v-if="Object.keys(userInfo).length === 0 || userInfo.infoProfect === 0" class="text-danger text-md" @click.stop="$router.push('/login')">{{ Object.keys(userInfo).length === 0 ? '价格登录可见' : userInfo.infoProfect === 0 ? '账号信息未完善' : '' }}</h6>-->
          <!--                <span v-if="Object.keys(userInfo).length > 0 && userInfo.infoProfect !== 0" class="text-danger" @click.stop="$emit('preview', item.id)">手机版效果</span>-->
          <!--                <a-icon :theme="item.isFavorite === 1 ? 'twoTone' : 'outlined' " class="heart" two-tone-color="#eb2f96" type="heart" @click.stop="handleHeart(item, index)" />-->
          <!--              </template>-->
          <!--            </a-card-meta>-->
          <!--          </a-card>-->
        </OwlCarousel>
      </a-col>
      <a-col flex="20px"></a-col>
    </a-row>
    <div class="flex-column">
      <h4 class="mt-30 mb-20">活动专区</h4>
      <img :src="require('@/assets/HomeBanner/act4.png')" alt="" style="max-width: 100%">
    </div>
    <!--  全部商品  -->
    <a-row style="margin-top: 32px" type="flex">
      <a-col :flex="1" class="aCol">
        <div class="top ">
          <h4>全部商品</h4>
        </div>
      </a-col>
      <a-col flex="20px"></a-col>
      <a-col :flex="1" class="aCol">
      </a-col>
    </a-row>
    <a-row type="flex">
      <a-col :flex="1">
        <div class="goods-item">
          <div class="flex-row flex-warp" style="flex-wrap: wrap; margin: 0 auto;  justify-content: space-around;">
            <GoodsNormalCard
              v-for="(item) in allGoods"
              :key="item.id"
              :goods-data="item"
              @preview="(id)=>{ $emit('preview', id) }"
            >
            </GoodsNormalCard>
            <!-- <div class="flex-row " style="flex-wrap: wrap; margin: 0 auto;  white-space:normal; grid-template-columns: repeat(auto-fill, 230px);column-gap: 62px;"> -->
            <!--            <a-card-->
            <!--              v-for="(item, index) in allGoods"-->
            <!--              :key="index"-->
            <!--              :hoverable="true"-->
            <!--              class="goodsCard m-10"-->
            <!--              style="max-width: 230px"-->
            <!--              @click="$router.push('/commodity/' + item.id)">-->
            <!--              <img-->
            <!--                slot="cover"-->
            <!--                :src="item.imgUrl"-->
            <!--                alt="example"-->
            <!--                style="width: 228px;  height: 238px"-->
            <!--              />-->
            <!--              <a-card-meta :title="item.name">-->
            <!--                <template slot="description">-->
            <!--                  <Ellipsis v-if="item.subTitle" :length="24">{{ item.subTitle }}</Ellipsis>-->
            <!--                  <div v-else class="icon-null"></div>-->
            <!--                  <div style="display: flex; justify-content: space-between">-->
            <!--                    <h5 v-if="Object.keys(userInfo).length !== 0 & userInfo.infoProfect !== 0 & userInfo.infoProfect !== 2">{{ '￥' + item.tradePrice }}</h5>-->
            <!--                    <div v-if="Object.keys(userInfo).length !== 0 & userInfo.infoProfect !== 0 & userInfo.infoProfect !== 2" style="color:green;" @click.stop="handleAddGoods(item, index)">{{ item.isPuint == 1 ? '已入库' : isGo == index ? '已入库' : '一键入库' }}</div>-->
            <!--                  </div>-->
            <!--                  <h6 v-if="Object.keys(userInfo).length === 0 || userInfo.infoProfect === 0" class="text-danger text-md" @click.stop="$router.push('/login')">{{ Object.keys(userInfo).length === 0 ? '价格登录可见' : userInfo.infoProfect === 0 ? '账号信息未完善' : '' }}</h6>-->
            <!--                  <span v-if="Object.keys(userInfo).length > 0 && userInfo.infoProfect !== 0" class="text-danger" @click.stop="$emit('preview', item.id)">手机版效果</span>-->
            <!--                  <a-icon :theme="item.isFavorite === 1 ? 'twoTone' : 'outlined' " class="heart" two-tone-color="#eb2f96" type="heart" @click.stop="handleHeart(item, index)" />-->
            <!--                </template>-->
            <!--              </a-card-meta>-->
            <!--            </a-card>-->
          </div>
        </div>
        <div v-if="isShow" class="flex-row flex-row-center flex-col-center bg-white p-20">
          <a-button style="width: 300px;font-size: 18px" type="primary" @click="pushAllGoods()">更多</a-button>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/7/26 9:46
 * @version 1.0
 * @description
 */
import OwlCarousel from '@/components/OwlCarousel/OwlCarousel'
import { mapGetters } from 'vuex'
import { SourceProductTypeMap } from '@/Map/SourceProductTypeMap'
import { favoriteProduct, getProductPage } from '@/api/Product'
import Ellipsis from '@/components/Ellipsis/Ellipsis'
import { addSelectGoods } from '@/api/SelectGoods'
import GoodsCard from '@/views/GoodsDetail/GoodsCard'
import GoodsNormalCard from '@/components/goodsNormalCard'
// import { Modal } from "ant-design-vue";
export default {
  name: 'CarouselRank',
  components: {
    GoodsNormalCard,
    GoodsCard,
    Ellipsis,
    SourceProductTypeMap,
    OwlCarousel,
  },
  data() {
    return {
      isGo: null,
      DailyRecommendations: [],
      platformSelection: [],
      allGoods: [],
      queryData: {
        current: 1,
        size: 18,
      },
      loadData: {
        current: 0,
        size: 72,
        pages: 1
      },
      isShow: true
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect'])
  },
  created() {
    this.featData()
    this.onAllFn()
    // 全部
    this.onGetProductPage(this.queryData)
  },
  // mounted() {
  //   const that = this
  //   window.onresize = function() {
  //     that.featData()
  //     that.onGetProductPage(Object.assign(that.queryData, { SourceProductType: 0 }))
  //     // getProductPage(Object.assign(that.queryData, { SourceProductType: 0 })).then(res => {
  //     //   if (that.allGoods.length > that.queryData.size) {
  //     //     that.allGoods = [...that.allGoods, ...res.data.records]
  //     //   } else {
  //     //     that.allGoods = res.data.records
  //     //   }
  //     // })
  //   }
  // },
  methods: {
    // 根据屏幕宽度动态请求数据 避免留空
    featData() {
      if (window.innerWidth > 1540) {
        console.log(window.innerWidth, 1540)
        this.queryData.size = 18
      } else if (window.innerWidth < 1540 && window.innerWidth > 900) {
        console.log(window.innerWidth, 1540, 900)
        this.queryData.size = 16
      } else if (window.innerWidth < 900) {
        console.log(window.innerWidth, 900)
        this.queryData.size = 15
      }
    },
    onAllFn() {
      // 每日推荐
      getProductPage(Object.assign(this.queryData, { SourceProductType: 3 })).then((res) => {
        this.DailyRecommendations = res.data.records
      })
      // 平台甄选
      getProductPage(Object.assign(this.queryData, { SourceProductType: 2 })).then((res) => {
        this.platformSelection = res.data.records
      })
    },
    handleHeart(item, index) {
      if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
        favoriteProduct(item.id).then(() => {
          item.isFavorite = item.isFavorite === 1 ? 0 : 1
          // this.onAllFn()
          // this.onFn()
        })
      }
    },
    onGetProductPage(query) {
      getProductPage(Object.assign(query, { SourceProductType: 0 })).then((res) => {
        if (!res.data.records.length) return
        if (res.data.records.length < this.queryData.size) {
          this.$message.error('不要在点了，已经到底啦')
          this.isShow = false
        }
          if (this.allGoods.length >= this.queryData.size) {
                this.allGoods = [...this.allGoods, ...res.data.records]
              } else {
                this.allGoods = res.data.records
              }
        // this.allGoods = [...this.allGoods, ...res.data.records]
        // this.loadData.pages = res.data.pages
      })
    },
    // 收藏后重新获取页面渲染新效果
    // onFn() {
    //   getProductPage({ current: this.queryData.size * this.queryData.current }).then(res => {
    //     this.allGoods = res.data.records
    //   })
    // },
    pushAllGoods() {
      this.loadData.current++
      this.queryData.current = this.queryData.current === 1 ? 2 : this.queryData.current
      this.onGetProductPage({ current: this.queryData.current++, size: this.queryData.size, SourceProductType: 0 })
    },
    //  一键入库
    handleAddGoods(item, index) {
      const that = this
      const ids = [item.id ? item.id : null]
      this.$confirm('你确定要将这些商品一键入库吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        }).then(() => {
          addSelectGoods(ids).then((res) => {
              if (res.code === 0) {
                that.$message.success('入库成功')
                that.isGo = true
              } else if (res.code === -1) {
                that.$message.error(res.msg)
              }
            })
        }).catch(() => {

        })
    },
  },
}
</script>

<style lang="scss" scoped>

  // .head-logo {
  //   width: 258px!important;
  // }

.checkbox{
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 10px;
  transform: scale(1.5);
}
.heart{
  font-size: 22px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.ant-card-meta-title {
  margin-bottom: 0;
}
.top {
  font-weight: bold;
  font-size: 22px;
  text-align: left;
  color: #202124;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  p{
    font-size: 20px;
  }
  .p {
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #707070;
    cursor: pointer;
  }
}

.top-span {
  display: block;

  span {
    display: block;
    font-family: "Roboto-Regular ",fangsong;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    color: #b9b9b9;
  }
}

.row {
  background: #fff;
  padding: 16px;

  .row-col {
    background: #F1F1F1;
    padding: 0px 16px 15px 16px;

    p {
      font-family: "PingFang SC Bold",fangsong;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #202124;
      padding: 10px 0px 15px 0px;
    }
  }
}

.row-col-img {
  display: flex;
  justify-content: space-between;

  img {
    display: block;
    width: 148px;
  }
}

.col-left {
  width: 180px;
  height: 70px;
  line-height: 70px;
  background: #000;
  font-family: "PingFang SC Bold";
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #fff;
}

.col-right {
  max-width: calc(100% - 180px);
  //max-width: 1320px;
  overflow: hidden;
}

.col-right-item {
  width: 140px;
  height: 70px;
  background: #fff;
  padding-top: 14px;
  padding-bottom: 8px;

  img {
    width: 30px;
    margin: 0 auto;
  }

  p {
    text-align: center;
    font-family: "Roboto-Regular ";
    font-weight: 400;
    font-size: 12px;
    color: #0a0d10;
  }
}

.goods-item {
  //display: flex;
  //flex-direction: row;
  //justify-content: center;
  background: #fff;
}

.carousel-box {
  background: #fff;
  width: 224px;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .carousel-box-left {
    //background: #52C41A;

    img {
      width: 70px;
    }
  }

  .carousel-box-right {
    //background: #4fabff;
    font-family: "Roboto-Regular ";
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #707070;

    p {
      margin-bottom: 10px;
    }
  }
}
</style>
