<template>
  <div class="userCenter">
    <UserWallet></UserWallet>
    <UserOrderStatus></UserOrderStatus>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/8/29 9:35
 * @version 1.0
 * @description 用户中心
 */

import Shortcuts from '@/components/Shortcuts'
import UserOrderStatus from '@/views/User/userCenter/userOrderStatus/userOrderStatus'
import { favoriteProduct, getProductObj, getProductPage } from '@/api/Product'

import { mapGetters } from 'vuex'
import UserWallet from '@/views/User/userCenter/userWallet/index.vue'
export default {
  name: 'UserCenter',
  components: { UserWallet, UserOrderStatus, Shortcuts, },
  data() {
    return {
      showPreview: false,
      previewLoading: false,
      previewData: {},
      DailyRecommendations: [],
      queryData: {
        current: 1,
        size: 15,
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created() {
    getProductPage(Object.assign(this.queryData, { type: 3 })).then((res) => {
      this.DailyRecommendations = res.data.records
    })
  },
  methods: {
    handleHeart(item, index) {
      favoriteProduct(item.id).then(() => {
        item.isFavorite === 1 ? item.isFavorite = 0 : item.isFavorite = 1
      })
    },
    preview(id) {
      this.showPreview = true
      this.previewLoading = true
      getProductObj(id).then(res => {
        this.previewData = res.data
        this.previewLoading = false
      })
    }
  },
}
</script>
