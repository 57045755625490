<template>
  <a-card :hoverable="true" class="goodsCard mr-10" style="max-width: 240px" @click="handleGoodsCard">
    <img slot="cover" :src="goodsData.imgUrl" alt="example" style="width: 238px;  height: 238px" />
    <a-card-meta :title="goodsData.name">
      <template slot="description">
        <Ellipsis :length="24" style="height: 16px" tooltip>{{ (goodsData.subTitle === null || goodsData.subTitle ===
          undefined || goodsData.subTitle === '') ? goodsData.name : goodsData.subTitle }}</Ellipsis>
        <div style="display: flex; justify-content: space-between">
          <h5 v-if="Object.keys(userInfo).length !== 0 & userInfo.infoProfect !== 0 & userInfo.infoProfect !== 2">{{ '￥' +
            goodsData.tradePrice }}</h5>
        </div>
        <h6 v-if="Object.keys(userInfo).length === 0 || userInfo.infoProfect === 0" class="text-danger text-md"
          @click.stop="$router.push($tp().IS_OPEN == 1 ? '/user/login' : '/login')">{{ Object.keys(userInfo).length === 0 ?
            '价格登录可见' : userInfo.infoProfect === 0 ? '账号信息未完善' : '' }}</h6>
        <div class="hover">
          <div class="h-full flex-col-center flex-row-between flex-row p-20">
            <a-icon v-if="Object.keys(userInfo).length > 0 && userInfo.infoProfect !== 0" class="mobile" type="mobile"
              @click.stop="$emit('preview', goodsData.id)" />
            <a-icon :theme="isFavorite === 1 ? 'twoTone' : 'outlined'" class="heart" two-tone-color="#eb2f96"
              type="heart" @click.stop="handleHeart(goodsData)" />
            <a-button v-if="Object.keys(userInfo).length !== 0 & userInfo.infoProfect !== 0 & userInfo.infoProfect !== 2"
              style="color:green;" @click.stop="handleAddGoods(goodsData)">{{ goodsData.isPuint == 1 ? '已入库' : isGo ?
                '已入库' : '一键入库' }}</a-button>
          </div>

        </div>
      </template>
    </a-card-meta>
  </a-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { addSelectGoods } from '@/api/SelectGoods'
import { favoriteProduct } from '@/api/Product'
import Ellipsis from '@/components/Ellipsis/Ellipsis'

/**
 * @author  XuHongli
 * @date  2022/11/7 9:48
 * @version 1.0
 * @description 商品展示卡片
 */
export default {
  name: 'GoodsNormalCard',
  components: {
    Ellipsis,
  },
  props: {
    goodsData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // 缓存已入库操作
      isGo: false,
      // 收藏操作缓存
      isFavorite: this.goodsData.isFavorite
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect'])
  },
  methods: {
    // 点击卡片跳转
    handleGoodsCard() {
      this.$router.push('/commodity/' + this.goodsData.id)
    },
    handleHeart(item, index) {
      if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
        favoriteProduct(item.id).then(() => {
          this.isFavorite = this.isFavorite === 1 ? 0 : 1
        })
      }
    },
    //  一键入库
    handleAddGoods(item) {
      const that = this
      const ids = [item.id ? item.id : null]
      if (item.isPuint == 1 || that.isGo) {
        this.$router.push('/user/shopLibrary')
      } else {
        this.$confirm('你确定要将这些商品一键入库吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        }).then(() => {
          addSelectGoods(ids).then((res) => {
            if (res.code === 0) {
              that.$message.success('入库成功')
              that.isGo = true
            } else if (res.code === -1) {
              that.$message.error(res.msg)
            }
          })
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.heart {
  font-size: 22px;
  bottom: 10px;
  right: 10px;
}

.mobile {
  font-size: 22px;
}

.hover {
  position: absolute;
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.5) 300%);
  width: 100%;
  height: 90px;
  top: 280px;
  left: 0;
}

.hover {
  opacity: 0
}

.goodsCard:hover {
  .hover {
    opacity: 1;
    transition: all .5s;
    display: block;
  }
}</style>
