<template>
  <div class="container">
    <a-row :gutter="20">
      <a-col :md="18" :span="18">
        <a-card :bordered="false" title="我的订单">
          <div class="userOrderStatus">
            <div @click="$router.push({ path: '/user/order?status=' + 0 })">
              <div>
                <a-badge :count="OrderStatus.waitPay">
                  <div class="lrc-badge1 icon-sprite"></div>
                </a-badge>
                <p>待付款</p>
              </div>
            </div>
            <div @click="$router.push({ path: '/user/order?status=' + 1 })">
              <div>
                <a-badge :count="OrderStatus.deliveredCount">
                  <div class="lrc-badge2 icon-sprite"></div>
                </a-badge>
                <p>待收货</p>
              </div>
            </div>
            <div @click="$router.push({ path: '/user/order?status=' + 3 })">
              <div>
                <a-badge :count="OrderStatus.tackCount">
                  <div class="lrc-badge3 icon-sprite"></div>
                </a-badge>
                <p>已完成</p>
              </div>
            </div>
            <div @click="$router.push('/user/exceptionOrder')">
              <div>
                <a-badge :count="OrderStatus.refundCount">
                  <div class="lrc-badge4 icon-sprite"></div>
                </a-badge>
                <p>异常订单</p>
              </div>
            </div>
            <div @click="$router.push('/user/dropOrder')">
              <div>
                <div class="lrc-badge5"></div>
                <p>代发订单</p>
              </div>
            </div>
            <div @click="$router.push('/user/order')">
              <div>
                <a-badge :count="Number(0)">
                  <div class="lrc-badge6 icon-sprite"></div>
                </a-badge>
                <p>全部订单</p>
              </div>
            </div>
          </div>
        </a-card>

        <div class="plus mt-20" style="background-color: #f5ca3a;">
          <p class="pt-20 pl-20" style="font-size: 16px;text-align: left;width: 100%; z-index: 9">
            <strong>每日推荐</strong>
          </p>
          <OwlCarousel v-if="productList.length > 0" style="width: 700px;">

            <div v-for="(item, index) in productList" :key="index" class="listClassItem"
              @click.stop="commodityClisck(item)">
              <div class="card-hezi">
                <img :src="item.imgUrl" alt="" />
              </div>
              <div class="hezi">
                <a-tooltip :title="item.name" placement="left">
                  <div class="name">{{ item.name }}</div>
                </a-tooltip>
                <div v-if="userInfo.infoProfect !== 1" class="goods_price">
                  <div v-if="Object.keys(userInfo).length == 0 || userInfo.infoProfect === 0" class="price"
                    @click.stop="$router.push($tp().IS_OPEN == 1 ? 'user/login' : '/login')">
                    {{ Object.keys(userInfo).length == 0 ? '价格登录可见' : userInfo.infoProfect === 0 ? '账号信息未完善' : '账号信息待审核'
                    }}
                  </div>
                  <div v-else-if="userInfo.infoProfect == 2" class="price">
                    {{ userInfo.infoProfect == 2 ? '账号信息待审核' : '' }}
                  </div>
                  <div v-else-if="userInfo.infoProfect == 3" class="price">
                    {{ userInfo.infoProfect == 3 ? '审核未通过' : '' }}
                  </div>
                </div>
                <div v-else class="goods_price">
                  <div class="costprice">
                    <div style="font-weight: 0;color: #f50;font-size: 10px;">{{ item.tradePrice ?
                      item.tradePrice.toFixed(2) : '0.00' }}</div>
                    <div>批发价</div>
                  </div>
                  <div class="profit">
                    <div style="color: #2ba7ff;font-size: 10px;">{{ (((item.marketPrice - item.tradePrice) /
                      item.tradePrice) * 100).toFixed(2) }}%</div>
                    <div>利润率</div>
                  </div>
                  <div>
                    <div style="font-weight: 0;color: #f50;font-size: 10px;">{{ (item.marketPrice -
                      item.tradePrice).toFixed(2) }}</div>
                    <div>利润</div>
                  </div>
                </div>
                <div class="flex-between sales-info">
                  <div class="sale_num">
                    <span>销</span><span>{{ item.sale || 0 }}</span>
                  </div>
                  <div v-if="userInfo.infoProfect == 1" style="color: #666;">市场价:<span style="color: #ff5300;">￥{{
                    item.marketPrice ? item.marketPrice.toFixed(2) : '0.00' }}</span></div>
                  <div v-if="item.tradeType" class="tag">{{ tp[item.tradeType] }}</div>
                </div>
                <div v-if="Object.keys(userInfo).length !== 0 && userInfo.infoProfect === 1" class="goods_btn">
                  <div class="markPrice" @click.stop="handleAddGoods(item, index)">
                    {{ item.isPuint === 1 || (isGo && isPuintIndex === index) ? '已入库' : '一键入库' }}
                  </div>
                  <div class="line"></div>
                  <div v-if="Object.keys(userInfo).length != 0 && userInfo.infoProfect === 1" class="shopuc"
                    @click.stop="handleHeart(item, index)">
                    <!--                :style="item.isFavorite === 1 ? 'color: #db4100' : ''"-->
                    <!--                :theme="item.isFavorite === 1 ? 'filled' : 'outlined' "-->
                    <a-icon style="font-size:15px;margin-right:5px;margin-top: 2px;vertical-align: sub;" theme="outlined"
                      two-tone-color="#db4100" type="heart" />{{ item.isFavorite === 1 ? '已收藏' : '收藏' }}
                  </div>
                </div>
                <div :class="'hover'">
                  <div class="xiang_si">
                    <a-button class="findBtn" shape="round" type="danger"
                      @click.stop="$router.push('/search')">找相似</a-button>
                  </div>
                </div>
              </div>
            </div>

          </OwlCarousel>
        </div>
      </a-col>
      <a-col :span="6">
        <a-card :bordered="false" title="我的关注">
          <div class="flex-row">
            <div style="flex: 1">
              <div style="font-weight: 900;font-size: 22px;color: 000">
                {{ attentionData.footCount }}
              </div>
              我的收藏
            </div>
            <div style="flex: 1">
              <div style="font-weight: 900;font-size: 22px;color: 000">
                {{ attentionData.puintCount }}
              </div>
              我的选品
            </div>
          </div>

        </a-card>
      </a-col>
    </a-row>


  </div>
</template>

<script>
import { getProductCount, getUserOrderStatus } from '@/api/Order'
import { mapGetters } from 'vuex'
import { favoriteProduct, getProductPage } from '@/api/Product'
import OwlCarousel from '@/components/OwlCarousel/OwlCarousel.vue'
import CarouselRank from '@/views/Home/CarouselRank.vue'

/**
 * @author  XuHongli
 * @date  2022/8/29 15:18
 * @version 1.0
 * @description 用户订单状态栏
 */
export default {
  name: 'UserStatus',
  components: { CarouselRank, OwlCarousel },
  data() {
    return {
      OrderStatus: {
        deliveredCount: 0,
        tackCount: 0,
        waitDeliveredCount: 0,
        waitPay: 0,
      },
      productList: [],
      isGo: false,
      isPuintIndex: 0,
      attentionData: {}
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect']),
    tp() {
      return {
        1: '保税直供',
        2: '完税进口',
        3: '国内贸易',
        4: '香港直邮',
        5: '海外直邮'
      }
    },

  },
  created() {
    if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
      getUserOrderStatus().then((res) => {
        this.OrderStatus = res.data
      })
      getProductCount().then(res => {
        if (Object.keys(res.data).length) this.attentionData = res.data
      })
      this.getPage()
    }
  },
  methods: {
    commodityClisck(item) {
      let PAGE_URL = window.origin + '/commodity/' + item.id
      window.open(PAGE_URL, '_blank')
    },
    handleHeart(item, index) {
      if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
        favoriteProduct(item.id).then((res) => {
          item.isFavorite === 1 ? item.isFavorite = 0 : item.isFavorite = 1
        })
      }
    },
    handleAddGoods(item, index) {
      const ids = [item.id ? item.id : null]
      if (item.isPuint == 1 || this.isGo) {
        this.$router.push('/user/shopLibrary')
      } else {
        this.$AddGroupModal({
          ids,
          handleOk: (val) => {
            if (val === 'ok') {
              if (val === 'ok') {
                item.isPuint = 1
              }
            } else {
              this.$emit('handleAddGoods')
            }
          }
        })
      }
    },
    getPage() {
      const data = {
        current: 1,
        size: 15,
        isRecommend: 1
      }
      getProductPage(data).then(res => {
        this.productList = res.data.records
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.goods_btn {
  width: 100%;
  height: 30px;
  position: absolute;
  top: 130px;
  left: 0;
  display: none;
  justify-content: space-between;
  align-items: center;
  opacity: .8;
  background: rgb(255, 101, 74);
}

.shopuc {
  border: none;
  font-size: 12px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #fff;
  border-radius: 0;
  width: 103px;
  height: 30px;
  line-height: 30px;
  background: rgba(255, 101, 74, .8);
  padding: 0;
}

.tag {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #e31436;
  padding: 1px 5px;
  color: #fff;
  font-size: 11px;
  background: #ff5d45;
  border-radius: 3px
}

.line {
  width: 1.1px;
  height: 27px;
  background: #ffd9d9;
}

.markPrice {
  width: 103px;
  height: 30px;
  font-size: 12px;
  background: rgba(255, 101, 74, .8);
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #fff;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

.findBtn {
  background-color: $color-orange;
  height: 30px;
  font-weight: 400;
  color: white;
  width: 100px;
}

.hezi {
  padding: 10px;

  .goods_price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //width: 170px;
    background: #f2f8ff;
    border-radius: 4px 4px 4px 4px;
    margin: 7px 0;
    padding: 5px;
    cursor: pointer;
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666;

    .costprice,
    .profit,
    .goods_price>div {
      text-align: center;
    }
  }
}

.flexs {
  justify-content: space-between;
}

.tps {
  display: flex;
  align-content: center;
  position: relative;
}

.hover {
  position: absolute;
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.5) 300%);
  width: 100%;
  height: 85px;
  line-height: 85px;
  left: 0;
  bottom: 0px;
}

.heart {
  font-size: 22px;
  bottom: 10px;
  right: 10px;
}

.hover {
  opacity: 0
}

.listClassItem:hover {
  .hover {
    opacity: 1;
    transition: all .5s;
    display: block;
  }

  .goods_btn {
    transition: all .5s;
    display: flex;
  }
}

.listClass {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.listClass1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.sales-info {
  color: #e46a1a;
  font-size: 12px;
  //height: 20px;
  //line-height: 20px;
  display: flex;
  justify-content: space-between;

  .sale_num {
    display: flex;

    span:first-child {
      //width: 19px;
      //height: 20px;
      padding: 0 3px;
      background: linear-gradient(90deg, #ff4f42, #ff743c);
      border-radius: 2px 0 0 2px;
      font-size: 10px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #fff;
      display: block;
    }

    span:last-child {
      //height: 20px;
      border-radius: 0 2px 2px 0;
      opacity: 1;
      border-top: 1px solid #fd7100;
      border-right: 1px solid #fd7100;
      border-bottom: 1px solid #fd7100;
      padding: 0 3px;
      font-size: 10px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #fd7100;
    }
  }
}

.price {
  color: red;
  font-size: 14px;
  text-align: left;
}

.flex-between {
  display: flex;
  align-items: center;
}

.listClassItem {
  width: 168px;
  height: 285px;
  background: #fff;
  text-align: center;
  margin-top: 12px;
  margin-right: 12px;
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;

  span {
    font-size: 12px;
  }
}

.listClassItem:hover {
  cursor: pointer;
  border: 1px solid #e31436;
  // box-shadow: 0px 1px 4px 5px rgb(235, 229, 232);
}

.card-hezi {
  width: 100%;
  height: 160px;
  overflow: hidden;
}

.listClassItem img:hover {
  transform: scale(1.1);
  border-radius: 5px 5px 0 0;
}

img {
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 100%;
  transition: all 0.6s ease .1s;
}

.name {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.listC {
  width: 225px;
  height: 355px;
  background: #fff;
  text-align: center;
  margin-top: 12px;
  margin-right: 18px;
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
}

.listC .card-hezi {
  height: 225px;
}
</style>

<style lang="scss" scoped>
.plus {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  &::before {
    background: url(//misc.360buyimg.com/user/myjd/home/2.0.19/css/i/x-01.png) no-repeat 0 0;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110px;
    background: url(//misc.360buyimg.com/user/myjd/home/2.0.19/css/i/z-01.png) no-repeat 0 0;
  }
}
.lrc-badge5 {
  margin: 0 auto;
  width: 39px;
  height: 41px;
  background-image: url("https://file.jingpingo.com/supply/material/a222e5ec-224e-4486-8158-d61c7a90ff01.png");
  background-size: cover;
}
.userOrderStatus {
  >div {
    float: left;
    box-sizing: border-box;
    padding: 13px 0 0;
    margin: 0 12px;
    width: 90px;
    height: 90px;
    text-align: center;
    color: #333;
    transition: all .4s ease;

    &:hover {
      cursor: pointer;
      border-radius: 5px;
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, .1);

      .lrc-badge1 {
        background-position: 0 -250px;
      }

      .lrc-badge2 {
        background-position: -80px -250px;
      }

      .lrc-badge3 {
        background-position: -160px -250px;
      }

      .lrc-badge4 {
        background-position: -240px -250px;
      }

      .lrc-badge5 {
        background-image: url("https://file.jingpingo.com/supply/material/f9f14377-1cd0-47b7-8287-e108439097ab.png");
      }

      .lrc-badge6 {
        background-position: -320px -250px;
      }
    }

    p {
      margin-top: 10px;
      height: 16px;
      line-height: 16px;
    }
  }

  .lrc-badge1 {
    margin: 3px auto;
    width: 44px;
    height: 35px;
    background-position: 0 -200px;
  }

  .lrc-badge2 {
    margin: 1px auto 2px;
    width: 40px;
    height: 38px;
    background-position: -80px -200px;
  }

  .lrc-badge3 {
    margin: 1px auto 2px;
    width: 41px;
    height: 38px;
    background-position: -160px -200px;
  }

  .lrc-badge4 {
    margin: 0 auto 1px;
    width: 39px;
    height: 41px;
    background-position: -240px -200px;
  }

  .lrc-badge6 {
    margin: 0 auto;
    width: 41px;
    height: 41px;
    background-position: -320px -200px;
  }
}</style>
