<template>
  <div class="goods-detail-contain-top  flex-start">
    <a-row justify="space-between" type="flex">
      <!-- <a-col flex="auto" style="max-width: 116px;min-width: 22px">
    </a-col> -->
      <a-col>
        <div style="display: flex;">
          <div class="goods-left">
            <div class="goods-img">
              <div class="spec-preview">
                <div>
                  <video v-if="isVideo" :src="productObj.productVideo" autoplay controls
                    style="width: 388px;height: 388px"></video>
                  <img v-else :src="mainImg" alt="" style="width: 388px;height: 388px" />
                  <div class="event" @mousemove="hadleer"></div>
                  <!-- 大图 -->
                  <div class="big">
                    <video v-if="isVideo" ref="big" :src="productObj.productVideo" autoplay controls
                      style="width: 388px;height: 388px"></video>
                    <img v-else ref="big" :src="mainImg" />
                  </div>
                  <!-- 遮罩 -->
                  <div ref="mask" class="mask"></div>
                </div>
              </div>
            </div>
            <!-- flex-column -->
            <div class="goodsImgs mx-10">
              <div class="spec-box flex-between">
                <div class="spec-items">
                  <div class="wrap" style="display: flex;">
                    <div v-for="(item, index) in JSON.parse(productObj.image)" :key="index"
                      :class="currentIndex === index ? 'actives' : ''" class="item" @mouseenter="hadleerimage(index)"
                      @mouseleave="hadleerimage(index)">
                      <img :ref="'img' + index" :src="item" alt="" @click="isCick(index, item)">
                    </div>
                    <div v-if="productObj.productVideo" class="item">
                      <video :src="productObj.productVideo" style="width: 54px; height: 54px;"
                        @click="isVideo = true"></video>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <a-col :flex="6">
            <div class="goods-right">
              <div class="describe-bottom-line">
                <div class="describe-top">
                  <span>{{ productObj.countryName }}</span>
                  <span>|</span>
                  <span>{{ productObj.brandName }}</span>
                </div>
                <div class="describe-flex describeMargin">
                  <div class="tag">{{ tp[productObj.tradeType] }}</div>
                  <div class="describe-title">{{ productObj.name }}</div>
                </div>
                <div>
                  <div class="describe-flex" style="background: rgb(242 236 236);;margin: 5px 0;padding: 5px;">
                    <div class="describe-label" style="width:60px;">价格：</div>
                    <div
                      v-if="Object.keys(userInfo).length !== 0 && (userInfo.infoProfect !== 0 && userInfo.infoProfect !== 2)"
                      class="describe-price">
                      ￥{{ ultimatelyResult.tradePrice ? ultimatelyResult.tradePrice.toFixed(2) :
                        productObj.tradePrice.toFixed(2) ? productObj.tradePrice.toFixed(2) : '0:00' }}
                    </div>
                    <div v-else class="describe-price">
                      {{ userInfo.infoProfect === 0 ? '账号信息未完善' : userInfo.infoProfect === 2 ? '账号信息待审核' : '价格登录可见' }}
                    </div>
                  </div>
                  <div class="describe-flex">
                    <div class="describe-label" style="width:60px;">配送至：</div>
                    <div>
                      <a-cascader ref="cascaderRef"
                        :fieldNames="{ label: 'name', value: 'regionId', children: 'children' }" :options="options"
                        :allowClear="true" placeholder="请选择省市区" style="width: 250px;" @change="onChange" />
                    </div>
                    <div
                      v-if="Object.keys(userInfo).length !== 0 && (userInfo.infoProfect !== 0 && userInfo.infoProfect !== 2)"
                      class="describe-label describe-left">运费：￥{{ freightPrices.toFixed(2) }}</div>
                  </div>
                  <!-- sku规格部分 -->
                  <div v-for="(item, index) in productSpec" :key="index + r" class="skudis" style="margin-top:10px">
                    <div class="describe-label" style="width:68px;">{{ item.name }}：</div>
                    <div class="skuClass">
                      <el-button v-for="(val, i) in item.value" :key="index + '-' + i"
                        :class="!notALL.includes(val) ? [form[item.name] == val ? 'active' : ''] : 'notALL'"
                        class="skuItem summary " plain size="mini" @click="optFor(item, val, index, i)">
                        {{ val }}
                      </el-button>
                    </div>
                  </div>
                  <div class="describe-flex" style="margin-top:10px">
                    <div class="describe-label" style="width:60px;">数量：</div>
                    <el-input-number v-model="quantity" :max="ultimatelyResult.stock" :min="1" label="描述文字"
                      @change="handleChange"></el-input-number>
                    <div class="describe-left">
                      (库存：{{ ultimatelyResult.stock ? ultimatelyResult.stock : 0 }}<span>件)</span>
                    </div>
                  </div>
                </div>

              </div>

              <div class="goods-form">
                <a-form-model :label-col="labelCol" :model="form" :wrapper-col="wrapperCol">
                  <a-form-model-item class="goods-button">
                    <a-button class="purchase-button" type="primary" @click="onSubmit">
                      立即购买
                    </a-button>
                    <a-button class="car-button" style="margin-left: 10px;" @click.stop="handleHeart(productObj.id)">
                      {{ isFavorite === 1 ? '已收藏' : '加入收藏' }}
                    </a-button>
                    <a-button
                      v-if="Object.keys(userInfo).length !== 0 & userInfo.infoProfect !== 0 & userInfo.infoProfect !== 2"
                      class="ru-button" style="margin-left: 10px;" @click.stop="handleAddGoods(productObj.id)">
                      {{ productObj.isPuint == 1 ? '已入库' : '一键入库' }}
                    </a-button>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </div>
          </a-col>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import AreaSelect from '@/components/AreaSelect'
import { mapGetters } from 'vuex'
import find from 'lodash.find'
import { favoriteProduct, freightProduct, inquireCityTree } from '@/api/Product'
import intersection from 'lodash.intersection'
import difference from 'lodash.difference'

/**
 * @author  XuHongli
 * @date  2022/9/6 18:30
 * @version 1.0
 * @description
 */
export default {
  name: 'GoodsCard',
  components: { AreaSelect },
  props: {
    productObj: {
      type: Object,
      required: true
    },
    productSpec: {
      type: Array,
      required: true
    },
    skuImageList: {
      type: Array,
      default: () => []
    },
    GuiSku: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect']),
    tp() {
      return {
        1: '保税直供',
        2: '完税进口',
        3: '国内贸易',
        4: '香港直邮',
        5: '海外直邮'
      }
    },
  },
  created() {
    this.productSpec.forEach((item) => {
      this.selectAbleList = [...this.selectAbleList, ...item.value]
    })
    if (this.infoProfect === 0 || this.infoProfect === 2) return
    if (Object.keys(this.userInfo).length > 0) {
      inquireCityTree().then((res) => {
        this.options = res.data
      }).catch((err) => {
        console.error('请求错误', err)
      })
    }
    this.productSpec.forEach((item) => {
      this.form[item.name] = item.value[0]
      console.log('productSpec', this.productObj.tradePrice, this.productObj)
      let date = this.productObj.skus
    })
    this.ultimatelyResult.stock = this.productObj.skus[0].stock
    this.ultimatelyResult.tradePrice = this.productObj.skus[0].tradePrice
    this.ultimatelyResult.enable = this.productObj.skus[0].enable
    this.ultimatelyResult.id = this.productObj.skus[0].id
    this.NewSkuDuibi.enable = this.productObj.skus[0].enable
    this.NewSkuDuibi.specNames = this.productObj.skus[0].specNames
    this.getNesku()
    let llp = []
    this.productObj.skus[0].specNames.forEach(item => {
      llp.push(item)
    })
    if (this.notALLSku.includes(llp[0])) {
      this.notALL = this.notALLSku.filter((item) => {
        return item !== llp[0]
      })
    }
  },
  data() {
    return {
      ultimatelyResult: {},
      newAddress: [],
      cityObj: {},
      options: [],
      isVideo: false,
      freightPrices: 0,
      skuObj: [],
      // 可以选择的sku值
      selectAbleList: [],
      r: Math.random() * 10000,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      dotPosition: 'left',
      mainImg: this.productObj.imgUrl,
      isFavorite: this.productObj.isFavorite,
      quantity: 1,
      form: { // 只用于处理sku
      },
      newArr: [],
      currentIndex: 0,
      describeValue: '',//获取省市区
      NewSkuDuibi: {
        enable: 0,
        specNames: [],
      },
      SkuDuibi: [],
      flagFalse: '',
      NewSku: '',
      notALLSku: [],
      notALL: []
    }
  },
  methods: {
    // 先判断禁用状态下的数据有那些
    getNesku(val) {
      let kk = this.productObj.skus
      let specNames = []
      kk.forEach(item => {
        if (item.enable == 0) {
          item.specNames.forEach(it => {
            specNames.push(it)
          })
        }
      })
      let newArr = specNames.filter((item, index) => { return specNames.indexOf(item) === index })
      console.log('这里有多少个禁用的', newArr)
      this.notALLSku = newArr
    },
    GetNewSkuDuibi(val) {
      const flag = this.NewSkuDuibi.specNames.includes(val)
      // GuiSku.specNames.includes(val)
      if (flag == true) this.flagFalse = flag
      return flag
    },

    // 一键入库
    handleAddGoods(item) {
      if (this.productObj.isPuint === 1) {
        this.$message.success('该商品已经入库')
        return
      }
      const that = this
      const ids = [item ? item : null]
      this.$AddGroupModal({
        ids,
        handleOk: (val) => {
          if (val === 'ok') {
            this.$emit('ok')
          }
        }
      })
    },
    handleChange(value) {
      this.quantity = value
      if (Object.keys(this.ultimatelyResult).length && this.newAddress.length) {
        const obj = {
          cityId: this.newAddress[1],
          productId: this.productObj.id ? this.productObj.id : '',
          productNum: this.quantity,
          skuId: this.ultimatelyResult.id
        }
        freightProduct(obj).then(res => {
          this.freightPrices = res.data ? res.data : 0
        }).catch(err => {
          console.log('请求错误数据', err)
        })
      }
    },
    isCick(i, item) {
      this.currentIndex = i
      this.mainImg = item
      this.isVideo = false
      console.log('下标', i)
    },
    // 鼠标移入切换主图
    hadleerimage(key) {
      this.mainImg = JSON.parse(this.productObj.image)[key]
    },
    hadleer(event) {
      if (this.$refs.mask && this.$refs.big) {
        //鼠标移入获取遮罩
        let mask = this.$refs.mask
        let big = this.$refs.big
        let left = event.offsetX - mask.offsetWidth / 2
        let top = event.offsetY - mask.offsetHeight / 2
        if (left <= 0) left = 0
        if (left >= mask.offsetWidth) left = mask.offsetWeight
        if (top <= 0) top = 0
        if (top >= mask.offsetHeight) top = mask.offsetHeight
        mask.style.left = left + 'px'
        mask.style.top = top + 'px'
        big.style.left = -2 * left + 'px'
        big.style.top = -2 * top + 'px'
      }
    },
    // 商品规格选中
    optFor(item, val, index, i) {
      this.NewSku = val
      if (Object.keys(this.userInfo).length == 0 || this.infoProfect === 0 || this.infoProfect === 2) return
      this.r = Math.random() * 1000
      this.ultimatelyResult = {}
      // 反选
      if (this.form[item.name] === val) {
        this.form[item.name] = ''
      } else {
        this.form[item.name] = val
      }
      // 获取选中的数组
      let selectVal = Object.values(this.form).filter((item) => {
        return item !== ''
      })
      selectVal = new Set(selectVal)
      selectVal = [...selectVal]
      console.log(selectVal, 'selectVal获取选中的数组')
      // 获取存在选中值的sku
      const allList = []
      this.productObj.skus.forEach((item) => {
        if (item.enable === '1') {
          const _arr = []
          item.specs.forEach((existList) => {
            _arr.push(existList.productSpecValueName)
          })
          allList.push(_arr)
        }
      })
      let existValArray = []
      allList.forEach((item) => {
        if (intersection(item, selectVal).length === selectVal.length) {
          existValArray.push(difference(item, selectVal))
        }
      })
      existValArray = [...new Set(existValArray.flat(Infinity))]
      this.selectAbleList = existValArray
      console.log(existValArray, '存在选中值的sku表')

      // 查找
      const formData = Object.values(this.form)
      let flag = true
      formData.forEach((item) => {
        if (item === '') {
          flag = false
        }
      })
      // 点击的时候判断一下是否属于禁用状态里的数据
      if (this.notALLSku.includes(val)) {
        let tp = this.notALLSku.filter((item) => { return item !== val })
        console.log(tp, '禁用的112')
        this.notALL = tp
      }
      if (!this.notALLSku.includes(val)) {
        let tps = this.notALLSku.filter((item) => { return item == val })
        this.notALL = tps
      }
      // 拿到选中后的数据做对比
      let sku = this.GuiSku
      // 取消选中后样式更换
      if (flag) {
        sku.forEach(item => {
          // 拿到数据后做对比
          if (item.specNames.join() == formData.join() == true) {
            this.NewSkuDuibi = item
          }
        })
        let tt = []

        this.productObj.skus.forEach((item) => {
          if (intersection(item.specNames, formData).length === formData.length) {
            this.ultimatelyResult = item
            this.mainImg = this.ultimatelyResult.picUrl
            this.NewSkuDuibi.enable = item.enable
            console.log(item, '最终值')

          }
        })

      }
      // if(!flag)  this.NewSkuDuibi  = []
      if (!this.newAddress.length) return
      const obj = {
        cityId: this.newAddress[1],
        productId: this.productObj.id ? this.productObj.id : '',
        productNum: this.quantity,
        skuId: this.ultimatelyResult.id
      }
      if (Object.keys(this.ultimatelyResult).length) {
        freightProduct(obj).then(res => {
          this.freightPrices = res.data ? res.data : 0
        }).catch(err => {
          console.log('请求错误数据', err)
        })
      }
    },
    // 数量事件
    onInputChange(e) {
      if (Object.keys(this.userInfo).length === 0 || this.infoProfect === 0 || this.infoProfect === 2) return
      console.log(35454564,)
      this.quantity = Number(e.target.value) === 0 || isNaN(Math.sign(Number(e.target.value))) === true ? 1 : Number(e.target.value)
      if (this.quantity > this.ultimatelyResult.stock) {
        this.$message.warning('该商品数量已达到最大库存量')
        return
      }
      if (!this.newAddress.length) return
      const obj = {
        cityId: this.newAddress[1],
        productId: this.productObj.id ? this.productObj.id : '',
        productNum: this.quantity,
        skuId: this.ultimatelyResult.id
      }
      if (Object.keys(this.ultimatelyResult).length) {
        freightProduct(obj).then(res => {
          this.freightPrices = res.data ? res.data : 0
        }).catch(err => {
          console.log('请求错误数据', err)
        })
      }
    },
    // 加减
    addSubtract(type) {
      if (Object.keys(this.userInfo).length == 0 || this.infoProfect === 0 || this.infoProfect === 2) return
      if (type === 0) {  // --
        if (this.quantity > 1) {
          this.quantity--
          if (!this.newAddress.length) return
          const obj = {
            cityId: this.newAddress[1],
            productId: this.productObj.id ? this.productObj.id : '',
            productNum: this.quantity,
            skuId: this.ultimatelyResult.id
          }
          if (Object.keys(this.ultimatelyResult).length) {
            freightProduct(obj).then(res => {
              this.freightPrices = res.data ? res.data : 0
            }).catch(err => {
              console.log('请求错误数据', err)
            })
          }
        }
      } else if (type === 1) {  // ++
        console.log('123123', this.ultimatelyResult)
        if (this.quantity >= this.ultimatelyResult.stock) {
          this.$message.warning('该商品数量已达到最大库存量')
          return
        } else if (!Object.keys(this.ultimatelyResult).length) {
          this.$message.warning('请先选择好规格')
          return
        } else {
          this.quantity++
        }
        if (!this.newAddress.length) return
        const obj = {
          cityId: this.newAddress[1],
          productId: this.productObj.id ? this.productObj.id : '',
          productNum: this.quantity,
          skuId: this.ultimatelyResult.id
        }
        freightProduct(obj).then(res => {
          this.freightPrices = res.data ? res.data : 0
        }).catch(err => {
          console.log('请求错误数据', err)
        })
      }
    },
    // 选择地址的回调
    onChange(val) {
      if (this.infoProfect === 0 || this.infoProfect === 2) {
        this.$message.warning(this.infoProfect === 0 ? '去完善信息' : '待后台审核')
        return
      }
      this.newAddress = val
      const obj = {
        cityId: val[1],
        productId: this.productObj.id ? this.productObj.id : '',
        productNum: this.quantity,
        skuId: this.ultimatelyResult.id
      }
      if (Object.keys(this.ultimatelyResult).length) {
        freightProduct(obj).then(res => {
          this.freightPrices = res.data ? res.data : 0
        }).catch(err => {
          console.error(err)
        })
      }
      console.log('地址', obj)
    },
    // 跳转至登录页
    go() {
      if (Object.keys(this.userInfo).length === 0) {
        if (this.$tp().IS_OPEN == 1) {
          let tps = window.origin + '/user/login'
          window.open(tps, '_blank')
        } else {
          this.$router.push({ path: '/login' })
        }
      }
    },
    // 生成路径表
    PowerSet(arr) {
      var ps = [[]]
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0, len = ps.length; j < len; j++) {
          ps.push(ps[j].concat(arr[i]))
        }
      }
      return ps
    },

    handleHeart(id) {
      if (!Object.keys(this.userInfo).length) {
        this.$message.warning('请先登录')
        return
      }
      if (this.infoProfect === 0 || this.infoProfect === 2) {
        this.$message.warning(this.infoProfect === 0 ? '该账号信息未完善' : this.infoProfect === 2 ? '等待后台审核' : '')
        return
      }
      favoriteProduct(id).then((res) => {
        this.isFavorite === 1 ? this.isFavorite = 0 : this.isFavorite = 1
      })
    },
    onSubmit() {
      console.log('this.NewSkuDuibi', this.NewSkuDuibi)
      if (this.NewSkuDuibi.enable == 0) return
      if (Object.keys(this.userInfo).length === 0 && this.infoProfect !== 1) {
        this.$message.warning(Object.keys(this.userInfo).length === 0 ? '请先登录' : this.infoProfect === 0 ? '账号信息未完善' : this.infoProfect === 2 ? '待后台审核信息' : '')
        return
      }
      if (Object.keys(this.userInfo).length && this.infoProfect !== 1) {
        this.$message.warning(Object.keys(this.userInfo).length === 0 ? '请先登录' : this.infoProfect === 0 ? '账号信息未完善' : this.infoProfect === 2 ? '待后台审核信息' : '')
        return
      }
      const keys = Object.keys(this.form)
      const specList = []
      for (let i = 0; i < keys.length; i++) {
        if (this.form[keys[i]] === '') {
          this.$notification.error({
            message: `${keys[i]}规格未选择`,
            description:
              `您还未选择${keys[i]},请您选择后重新再试.`,
          })
          return
        }
        specList.push(this.form[keys[i]])
      }

      if (this.ultimatelyResult.stock <= 0 || this.quantity <= 0) {
        this.$message.warning('该商品库存或者数量不足')
        return
      }
      if (this.quantity > this.ultimatelyResult.stock) {
        this.$message.warning('该商品库存或者数量不足')
        return
      }
      const skuInfo = find(this.productObj.skus, (item) => {
        let flag = true
        for (let i = 0; i < specList.length; i++) {
          if (item.specNames.indexOf(specList[i]) === -1) {
            flag = false
            return
          }
        }
        return flag
      })
      this.$router.push({
        name: 'CheckOrder',
        params: { 'skuInfo': skuInfo, quantity: String(this.quantity), productId: this.productObj.id, spuInfo: JSON.stringify(this.productObj), 'freightPrices': this.freightPrices }
      })
    },
  },
}
</script>

<style scoped>
.skuItem {
  cursor: v-bind(cursor);
}
</style>


<style lang="scss" scoped>
.notALL {
  cursor: not-allowed;
  border: 1px solid #ddd !important;
  color: #ddd;
}

.notALL :hover {
  color: #ddd;
}


.skuClass {
  // display: flex;
  // flex-flow: wrap;
  width: 100%;
}

.el-button+.el-button,
.el-checkbox.is-bordered+.el-checkbox.is-bordered {
  margin-left: 4px;
}

.summary .active {
  color: #2e8ed4;
  border: 1px solid #2e8ed4;
  // background: url(https://images.houniao.hk/wstmart/home/view/default/public/product/images/selected-triangle.png) 100% 100% no-repeat;
}

.skudis {
  display: flex;
  align-content: flex-start;
}

.describe-bottom-line {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}

.describe-warp {
  display: flex;
  flex-wrap: wrap;
}

.describe-btn {
  width: 130px;
  padding: 5px 10px;
  border: 1px solid #ddd;
}

.describe-btn::after {
  position: absolute;
  top: 0;
  left: 0;
  border-color: transparent;
  border-style: solid;
}

.describe-btn::before {
  border-width: 0;
}

.describe-btn::after {
  border-radius: 0;
  border-width: 12px;
  border-left-color: green;
  border-top-color: green;
}

.describe-left {
  margin-left: 15px;
}

.describe-price {
  color: #ff2900;
  font-size: 26px;
  font-weight: 600;
}

.describe-label {
  margin: 10px 10px;
}

.describeMargin {
  margin: 10px 0;
}

.describe-title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 15px;
}

.tag {
  color: #e31436;
  padding: 1px 5px;
  line-height: 20px;
  width: 85px;
  text-align: center;
  height: 20px;
  color: #fff;
  font-size: 10px;
  background: rgb(255, 153, 0);
  border-radius: 3px
}

.describe-flex {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.describe-top {
  border-bottom: 1px solid #ddd;
  padding: 5px 0;

  span {
    margin-right: 10px;
  }
}


.ant-input:hover {
  border-color: #999999;
  border-right-width: 1px !important;
}

.ant-input {
  width: 110px;
  text-align: center;
}

.iconjian {
  width: 50px;
  height: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #8C8C8C;
  border: 1px solid #D9D9D9;
  border-right: none;
}

.iconjian:last-child {
  border-left: none;
  border-right: 1px solid #D9D9D9;
}

.goods-detail-contain-top {
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 15px auto;
  width: 1200px;
  padding: 20px 20px 0;
  align-items: flex-start;
  text-align: left;
}

.spec-box {
  padding: 20px 0;
}

.spec-items {
  width: 380px;

  // overflow: hidden;
  // background: #a0bda2;tradePrice

}

.wrap {
  transition: all .5s ease;
  // display: flex;
  width: 100%;

  // align-items: center;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  // background: #007108;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.wrap::-webkit-scrollbar {
  width: 2px;
  height: 4px;
  margin-top: 5px;
}

.wrap::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background: fade(rgb(253, 159, 18), 30%);
}

.wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: fade(#fd2900, 30%);
}

.item {
  // display: inline-block;
  width: 58px;
  height: 58px;
  border: 2px solid #fff;
  margin-right: 6px;
  // white-space:nowrap;

  img {
    width: 100%;
    height: 100%;
  }
}

.item:hover {
  border-color: #f55;
}

.actives {
  border-color: #f55;
}

.spec-preview {
  position: relative;
  width: 390px;
  height: 390px;
  border: 1px solid #ccc;

  img {
    width: 100%;
    height: 100%;
  }

  .event {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 998;
  }

  .mask {
    width: 50%;
    height: 50%;
    background-color: rgba(0, 255, 0, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    display: none;
  }

  .big {
    width: 140%;
    height: 140%;
    position: absolute;
    top: -1px;
    left: 100%;
    border: 1px solid #aaa;
    overflow: hidden;
    z-index: 998;
    display: none;
    background: white;

    img {
      width: 200%;
      max-width: 200%;
      height: 200%;
      position: absolute;
      left: 15px;
      top: 0;
    }
  }

  .event:hover~.mask,
  .event:hover~.big {
    display: block;
  }
}

.active {
  border: 1px solid #f55 !important;
  color: #f55 !important;
}

.allc {
  border: 1px solid #e31436 !important;
  color: #e31436 !important;
}

.notAll {
  color: #2e8ed4 !important;
}

.noActive {
  border: 1px solid #dddddd !important;
  color: #858882 !important;
  cursor: not-allowed;
}

.bgColorInfo {
  background: red;
  color: #fff;
}

// 去除input类型Type的属性样式，默认携带按钮
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* 火狐 */
input {
  -moz-appearance: textfield;
}

.goodsImgs {
  height: auto;
  //overflow-x: scroll;
  //overflow-y: hidden;
  display: flex;
  align-items: center;



  // 滚动条样式
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
    background-color: #99a9bf;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #d3dce6;
  }
}

.goods-img {
  //max-width: 528px;
  //min-width: 326px;
  // margin-left: 20px;
  margin: 0 auto;
}

.goods-left {}

.goods-right {
  flex: 1;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 500;
    line-height: 25px;
    width: auto;
    margin: 0 10px;
    font-size: 16px;
    text-align: left;
    color: #010200;
    border-bottom: 1px solid #e0e0e0;
  }

  .goods_one_box {
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;

    .left {
      color: #010200;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .right {
      font-size: .7rem;
      color: #868585;
    }
  }

  .goods_title {
    padding-left: 5px;
    font-size: .8rem;
    font-weight: 550;
    color: #010200;
    height: 35px;
    line-height: 35px;
  }

  .address {
    padding: 0 0 0 15px;
  }

  .price {
    font-family: "Roboto-Regular ", serif;
    font-weight: 600;
    font-size: 18px;
    text-align: left;

    span {
      font-family: "Roboto-Regular ", serif;
      font-weight: 400;
      font-size: 12px;
      text-align: left;
      color: #b9b9b9;
    }
  }

  .ant-form.ant-form-horizontal {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    flex: 1;
  }

  .goods-form {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    .ant-form-item {
      margin-bottom: 10px;
    }

    ::v-deep .ant-form-item-label>label {
      font-family: "Roboto-Regular ", serif;
      font-weight: 400;
      text-align: left;
      color: #707070;
      ;
    }

    ::v-deep .ant-form-item-label {
      text-align: center;
      width: 74px;
    }

    p {
      margin: 0;
      padding: 0;
    }

    .p1 {
      font-family: "PingFang SC Bold", serif;
      font-weight: bold;
      font-size: 14px;
      color: #b9b9b9;

      .span-green {
        color: #007108;
      }

      .span-black {
        color: #202124;
      }

      .span-blue {
        color: #0364FF;
        margin: 0 4px;
      }
    }

    .p2 {
      font-family: "PingFang SC", serif;
      font-weight: 400;
      font-size: 12px;
      text-align: left;
      color: #b9b9b9;
      line-height: 4px;

      span {
        color: #202124;
      }
    }

    .goods-button {
      margin-top: auto;
      height: 66px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-family: "PingFang SC Bold", serif;
      font-weight: bold;
      font-size: 14px;

      .ant-btn {
        width: 180px;
        min-width: 100px;
        height: 42px;
        border-radius: 2px;
        color: #202124;
        border: none;
      }

      .purchase-button {
        background: rgb(253, 159, 18) !important;
        border-radius: 4px !important;
        color: #fff;
      }

      .car-button {
        background: #fff !important;
        border: 1px solid rgb(253, 159, 18) !important;
        color: rgb(253, 159, 18);
        border-radius: 4px !important;

      }

      .ru-button {
        background: rgb(12, 187, 140) !important;
        color: #fff;
        border-radius: 4px !important;
      }

      .icon {
        font-size: 20px;
        transform: translate(10px, 4px);
      }
    }

    ::v-deep .goods-button .ant-col-14 {
      width: 100%;
    }

    .goods-bottom {
      font-family: "PingFang SC Bold", serif;
      font-weight: bold;
      font-size: 12px;
      text-align: left;
      color: #202124;

      .span {
        margin-right: 20px;
      }

    }

    .button-icon {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .button-icon-left {
      //border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .button-icon-right {
      //border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}</style>
