/**
 * @author  XuHongli
 * @date  2022/9/5 17:45
 * @version 1.0
 * @description 产品来源状态Map
 */

export const SourceProductTypeMap = {
  0: {
    status: 'default',
    text: '全部'
  },
  // 1: {
  //   status: 'processing',
  //   text: '品牌商品'
  // },
  2: {
    status: 'success',
    text: '平台甄选'
  },
  3: {
    status: 'error',
    text: '每日推荐'
  },
}
